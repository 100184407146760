<!-- <template>
  <div
    class="nav-bar__item nav-bar__item-price"
    :style="styles"
    :aria-label="item.text"
    role="tab"
    @click="onClick"
  >
    <span
      :class="{
        'nav-bar__item_text': true,
        'nav-bar__item_gap': true,
        'nav-bar__item_highlight' : [ascValue, descValue].includes(item.active)
      }"
    >
      {{ item.text }}
      <span class="icon-wrap">
        <i
          class="iconfont icon-price_px_"
          :style="`top: -11px;${item.active === ascValue && 'color: #222;'}`"
        ></i>
        <i
          class="iconfont icon-price_px_-"
          :style="`top: 2px;${item.active === descValue && 'color: #222;'}`"
        ></i>
      </span>
    </span>
  </div>
</template> -->

<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NavBarPrice',
  emits: ['click'],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    // 样式集合
    styles: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    
    ascValue () {
      return this.item?.data[0]?.value || 0
    },
    descValue () {
      return this.item?.data[1]?.value || 0
    }
  },
  methods: {
    onClick () {
      const active = this.item.active || 0


      this.$emit('click', {
        ...this.item,
        data: { 
          ...this.item.data,
          value: active === this.ascValue 
            ? this. descValue
            : this.ascValue 
        }
      })
    }
  },
  render({ $props: props, $attrs: listeners }) {
    const { item = {}, styles = {} } = props
    const { text = '', active = 0 } = item
    const ascValue = item?.data?.[0]?.value || 0
    const descValue = item?.data?.[1]?.value || 0
    const navBarItemTextClassList = [
      'nav-bar__item_text',
      'nav-bar__item_gap',
      {
        'nav-bar__item_highlight': [ascValue, descValue].includes(active)
      }
    ]
    const ascIconClassList = [
      'iconfont',
      'icon-price_px_',
      'icon-asc',
      {
        'icon-price__active': active === ascValue
      }
    ]
    const descIconClassList = [
      'iconfont',
      'icon-price_px_-',
      'icon-desc',
      {
        'icon-price__active': active === descValue
      }
    ]
    return (
      <div
        class="nav-bar__item nav-bar__item-price"
        style={styles}
        aria-label={item.text}
        role="tab"
        onClick={function handleClickNavBarPrice() {
          listeners?.['onClick']?.({
            text,
            data: {
              ...item.data,
              value: active === ascValue ? descValue : ascValue
            }
          })
        }}
      >
        <span class={navBarItemTextClassList} >
          { text }
          <span class="icon-wrap">
            <i class={ascIconClassList}></i>
            <i class={descIconClassList}></i>
          </span>
        </span>
      </div>
    )
  },
})
</script>

<style lang="less" scoped>
.nav-bar__item_text {
  .icon-wrap {
    display: inline-block;
    width: 10px;
    position: relative;
    >i.iconfont {
      font-size: 12px;
      color: #959595;
      line-height: 1;
    }
    i.icon-price__active {
      color: #222;
    }
    i.icon-asc {
      top: -11px;
    }
    i.icon-desc {
      top: 2px;
    }
  }
}
</style>
