<template>
  <div
    :class="[
      'nav-bar__item', 
      {
        'nav-bar__item_seperator' : item.hasSeperator,
        'nav-bar__item_disable': item.disable
      },
    ]"
    :style="styles"
    tabindex="0"
    :aria-label="item.text"
    role="tab"
    @click="onClick"
    @focus="$emit('navFocus', $event)"
  >
    <span
      class="nav-bar__item_text"
      :class="{
        'nav-bar__item_highlight' : (item.hasCountBadge ? item.panelContentSelected : (item.panelContentSelected || item.active)),
        'nav-bar__item_gap': item.icons
      }"
      :style="item.styles"
    >
      {{ item.text }}

      <!-- icon配置 -->
      <template v-if="item.icons">
        <sui_icon_filter_16px
          class="nav-bar__item-filter-icon"
          size="16px"
        />
        <span
          v-if="item.hasCountBadge && selectedNum"
          class="nav-bar__item_badge"
        >
          {{ selectedNum }}
        </span>
      </template>
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { sui_icon_filter_16px } from '@shein-aidc/icon-vue3'
export default defineComponent({
  name: 'NavBarSide',
  components: {
    sui_icon_filter_16px
  },
  emits: ['click', 'navFocus'],
  props: {
    // 选中的筛选数量
    item: {
      type: Object,
      default: () => ({})
    },
    // 选中的筛选数量
    selectedNum: {
      type: Number,
      default: 0
    },
    // 样式集合
    styles: {
      type: Object,
      default: () => ({
        navItem: {},
        navBar: {}
      })
    }
  },
  methods: {
    onClick () {
      const item = this.item
      if (item.disable) return
      // 激活高亮和icon
      item.active = false
      this.$emit('click', { ...item })
    },
  },
})
</script>

<style lang="less">
.nav-bar__item_text {
  .nav-bar__item-filter-icon {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(3px);
    color: #666;
    font-weight: 400;
  }
}
</style>
