/* 职责： 用于fitler ada  */

const ADA_FLAG = `adaflag` // !自定义属性,  用于在点击事件时判断。  这个很重要

/**
 * @description:  ADA 设置tabindex； ADA_FLAG 来进行判断
 * 显示弹框后 给每个元素设置0
 */
class FilterAda {
  constructor() {
    this.parentEl = null
    this.fn = null
    this.attributeElList = [] // 每次了自定义属性的元素集合
    this.allCacheElList = new Map()
    this.index = null
    this.isAda = false        // 是否开启ada 辅助。
  }

  async open(slots, index, isAda, fn) {   
    this.isAda = isAda
    this.clear()
    if (!slots || !this.isAda) return     // 没有开启ADA 返回
    this.removeAllItemTabindex()          // 清理一下之前taindex  并清空map
    this.parentEl = await this.getEls(slots)
    if (!this.parentEl) return
    this.fn = fn
    this.index = index
    
    this.getAttributeEl(this.parentEl) // 1. 获取所有带有自定义属性的元素
    this.handleBottomOperation() // 获取操作底部元素，并监听click 有的filter没有

    this.setItemTabindex()             //  遍历所有元素，添加tabindex
    this.handleLiBack() // 监听点击事件。
    // this.addLastChild() // 在尾部添加一个元素， 用于最后一个元素焦点的时候， 将光标移动到第一个上

    this.allCacheElList.set(this.index, this.attributeElList)

    setTimeout(() => {
      this.setFirstChildFocus()
    }, 300)
  }

  // filter 里面点击数据更新， 重新tabindex赋值, 外部调用。
  slideFilterInit() {
    if (!this.isAda) return
    setTimeout(() => {
      this.getAttributeEl(document.querySelector('.side-filter__main')) // 1. 获取所有带有自定义属性的元素
      this.setItemTabindex()             //  遍历所有元素，添加tabindex
      this.allCacheElList.set(this.index, this.attributeElList)
    }, 600)
  }

  getEls(ins) {
    return new Promise((resolve) => {
      // let el = ctx?.ctx?.$slots?.default?.()?.[0]?.elm
      let el = ins.$el
      if (el.nodeType != 1) {
        // 没有获取到元素
        setTimeout(() => {
          resolve(el)
        }, 600)
      } else {
        resolve(el)
      }
    })
  }


  /* 获取带了自定义属性的所有元素 */
  getAttributeEl(node) {
    if (!node || node.nodeType !== 1) return

    for (let i = 0; i < node.childNodes.length; i++) {
      const childNode = node.childNodes[i]
      if (childNode.nodeType == 1) {
        this.getAttributeEl(childNode) // 递归获取子元素
        if (childNode.hasAttribute(ADA_FLAG)) {
          this.attributeElList.push(childNode)
        }
      }
    }
  }

  /* 给每一个元素设置tabindex = 0 */
  setItemTabindex() {
    this.attributeElList.forEach((node) => {
      node.setAttribute('tabindex', 0)
      node.removeAttribute('aria-hidden')
    })
  }

  /* 这里只能捕获到 sort筛选， 点击后会自动关闭， 其他是触发 done按钮 */
  handleLiBack() {
    let flag = false
    const that = this
    this.parentEl.addEventListener(
      'click',
      function(e) {
        if (!flag && e.target.hasAttribute(ADA_FLAG)) {
          flag = true
          that.fn && that.fn() // 退出焦点
        }
      },
      false
    )
  }

  // 底部按钮 reset  done
  handleBottomOperation() {
    const bottomBtnOperationEl = this.parentEl?.querySelector?.(
      '.bottom-operation__right'
    )
    if (!bottomBtnOperationEl) return
    const [, DoneEl] = bottomBtnOperationEl.children
    DoneEl.addEventListener('click', () => {
      this.fn && this.fn() // 退出焦点
    })
  }

  // addLastChild() {
  //   if (!this.attributeElList.length || this.isIosDevice()) return  // ios系统 不处理
  //   const FLAG_EL = 'lastel' // 标识最后一个元素
    
  //   const lastNode = this.attributeElList[this.attributeElList.length - 1]
  //   const parentEl = lastNode.parentNode  // 父元素
  //   this.clearAddElement(parentEl, FLAG_EL)
  //   this.addLastEl(parentEl, FLAG_EL)
  // }

  // // 判断是否创建过尾部子元素
  // clearAddElement(parentEl, flagEl) {
  //   const removeEl = this.attributeElList.find(node => node.hasAttribute(flagEl))
  //   if (removeEl) {
  //     parentEl.removeChild(removeEl)
  //   }
  // }

  // addLastEl(parentNode, flagEl) {  
  //   const createEl = document.createElement('a')
  //   // createEl.style.cssText = 'height:1px;width:50px;'
  //   // createEl.setAttribute('tabindex', 0)
  //   createEl.setAttribute('href', 'javascript:void(0)')
  //   createEl.setAttribute(flagEl, 1)
  //   parentNode.appendChild(createEl)
  //   this.lastElListener(createEl)

  //   this.attributeElList.push(createEl)
  // }

  // // 聚焦最后一元素时将光标聚焦到第一个元素
  // lastElListener(lastEl) {
  //   lastEl.addEventListener('focus', () => {
  //     alert('24')
  //     this.setFirstChildFocus()
  //   }, true)
  // }

  // 设置焦点触发
  setFirstChildFocus() {
    this.attributeElList[0]?.focus()
  }

  clear() {
    this.parentEl = null
    this.fn = null
    this.attributeElList = []
  }
  /**
   * @description: 清楚所有tabindex -1， 由于元素在没有打开的时候 也能聚焦
   */
  removeAllItemTabindex() {
    if (!this.allCacheElList.size) return
    for (let [, nodes] of this.allCacheElList) {
      nodes.forEach(childNode => {
        if (childNode.hasAttribute(ADA_FLAG)) {
          childNode.removeAttribute('tabindex')
          childNode.setAttribute('aria-hidden', true)
          // childNode.setAttribute(ADA_FLAG, 1)
        }
      })
    }
    this.allCacheElList.clear()
  }

  isIosDevice() {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  }
}

export default new FilterAda()
