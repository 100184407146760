<!-- <template>
  <div
    class="nav-bar__item"
    :style="styles"
    :aria-label="item.text"
    role="tab"
    @click="onClick"
  >
    <span
      :class="{
        'nav-bar__item_text': true,
        'nav-bar__item_highlight' : item.active
      }"
    >
      {{ item.text }}
    </span>
  </div>
</template> -->

<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NavBaPopular',
  emits: ['click'],
  props: {
    reload: Boolean,
    item: {
      type: Object,
      default: () => ({})
    },
    // 样式集合
    styles: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onClick () {
      this.$emit('click', {
        ...this.item,
        data: {
          ...this.item.data
        }
      })
    }
  },
  render({ $props: props, $attrs: listeners }) {
    const { styles = {}, item = {} } = props
    const navBarItemClassList = [
      'nav-bar__item_text',
      {
        'nav-bar__item_highlight': item.active
      }
    ]
    return (
      <div
        class="nav-bar__item"
        style={styles}
        role="tab"
        aria-label={item.text || ''}
        onClick={function handleClickNavBarPopular() {
          listeners?.['onClick']?.({
            text: item.text,
            data: {
              ...item.data
            }
          })
        }}
      >
        <span
          class={navBarItemClassList}
        >
          { item.text }
        </span>
      </div>
    )
  },
})
</script>
